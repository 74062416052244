import { ethers } from 'ethers';

// if (location.search && location.search.match(/enter-test-mode/)) localStorage.setItem('IsWolfTownTest', 'true');
// localStorage.setItem('IsWolfTownTest', 'true');
// const IsTest = localStorage.getItem('IsWolfTownTest') === 'true';

let DEBUG_ADDRESS = '';


export const IsTest = false;

export const ImgUrl = "https://metainfo.wasai.space";

const IsDevelopment = process.env.NODE_ENV === 'development';
export const Constants = {
  ArenaHistoryBlock: (24 * 60 * 60 * 30) / 3,
  DEBUG_ADDRESS,
  IsDevelopment,
  DEFAULT_SEED: '0x0000000000000000000000000000000000000000000000000000000000000000',
  /**
   * 每个邀请最多可持续的时间 (hours)
   */
  INVITATION_DURATION: 72,

  IsTest,
  BASE_URL: 'https://app.wolftown.world/animals',
  BASE_IMAGE_URL: 'https://app.wolftown.world/images/animals',
  API_SERVE: 'https://app.wolftown.world',
  OPENSEA_URL: 'https://opensea.io/collection/new-oasis-planet-2',
  Chain: {
    ID: '1',
    PRC: 'https://web3.mytokenpocket.vip',
    Name: 'Eth Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    ScanView: 'https://etherscan.io/',
    AddressView: 'https://etherscan.io/address',
    TxView: 'https://etherscan.io/tx',
  },

  emergencyWolfLeave: false,

  Building: {
    OwnershipTotal: 10000000,
  },
  Contract: {
    BankAddress: '0x1f0c693F5cD661126e43b25a96FA703a0C8F2543',
    WTWool: '0xAA15535fd352F60B937B4e59D8a2D52110A419dD',
    USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    WasaiMint: '0xe57F05aB9dc009c08bb082C0e24c420cbb22AC82',
    WasaiMintUSDT: '0x9cbb01b1E9f894CF96C8997E1b04E61CC2e19664', 
    WasaiNFT: '0x2Dc7Acc59C39F4E0a373DFe0e55c715E7eBefb7E',   //admin  0xB0d2150b8da76eEe158dAB5aB1C4893aB5b1A4F6
    BatchOpen:"0x0e7362026cB0C8E6A0F765d311b46d95aF3905c4",
    MarketPlace:"0xE464862E0b2Bf54B8f6194B7f6c9f0fCE59ed21d"
  },

  //batch admin 0xc501fe2954f6BAC66E74e93EA0C841F1b5958B93
};
export const AbiConfig: Record<keyof typeof Constants.Contract, any> = {
  BankAddress: require('src/utils/abi/wtWool'),
  WTWool: require('src/utils/abi/wtWool'),
  USDT: require('src/utils/abi/wtWool'), 
  WasaiMint: require('src/utils/abi/WasaiMint.json'),
  WasaiMintUSDT: require('src/utils/abi/WasaiMint.json'),
  WasaiNFT: require('src/utils/abi/WasaiNFT.json'), 
  BatchOpen: require('src/utils/abi/Batch.json'),
  MarketPlace: require('src/utils/abi/MarketPlace.json'),
};
if (!IsDevelopment) {
  console.log = () => null;
  console.error = () => null;
  console.info = () => null;
}
if (IsTest) {
  Object.assign(Constants, {
    BASE_URL: 'https://app.test.wolftown.world/animals',
    BASE_IMAGE_URL: 'https://app.test.wolftown.world/images/animals',
    API_SERVE: 'https://app.test.wolftown.world',
    OPENSEA_URL: 'https://opensea.io/zh-CN/collection/wasai-1',
    Chain: {
      ID: '8453',
      PRC: 'https://developer-access-mainnet.base.org',
      Name: 'Base Chain',
      nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      ScanView: 'https://www.coinbase.com/blog/introducing-base',
      AddressView: 'https://www.coinbase.com/blog/introducing-base/address',
      TxView: 'https://www.coinbase.com/blog/introducing-base/tx',
    },
    Contract: {
      // api
      WoolfTownAPI: '0xbFE5a21f0c153144BE0c65BE961b01Bf52EE1B0f', 
      // contract
      WTWool: '0x808156c8510f3b07209762d1357da452Bc740067',
      USDT: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      WasaiMint: '0x28E31353d87C994748c61B9E6692B4E7bFb92951',
      WasaiNFT: '0x223410a7E4d6c3a29d6E5C96e61f910526835746',  //admin  0x62Fb5E1D3e516CCb9B55Ff15b1F1C182975Ec308
      BatchOpen:"0x77395936b39263bc8540efc2f1a6d1822383ee42"
    },
  });
}
