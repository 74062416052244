/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-15 21:48:14
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-15 22:34:17
 * @FilePath: \wasai-nft-web\src\components\pagehtml\BlogPage.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/css/style.css'
const BlogPageHeader = () => {
    return (
        // url(assets/images/header/bg.jpg)
        <section className="page-header bg--cover m100" style={{backgroundImage: "url(" + require('../../assets/images/header/bg.jpg') + ")"}}>
            <div className="container">
                <div className="page-header__content text-center">
                    <h3 className="text-uppercase">Our Blog Post</h3>
                    <nav style={{'--bs-breadcrumb-divider': '-'}} aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Blog</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>
    );
}

export default BlogPageHeader;
