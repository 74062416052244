/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-15 21:51:41
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-15 22:41:13
 * @FilePath: \wasai-nft-web\src\components\pagehtml\Blog.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState, useEffect } from "react";

import { useTranslation } from "../../utils/contexts/Localization";
import { BigNumber, Contract, providers, utils } from "ethers";
import {
  getContractHandler,
  _getAddress,
  useWalletHook,
  connectMetamask,
  watchTransaction,
  switchToMainnet,
  IsBscNet,
} from "../../utils/ethereum";
import useToast from "../../utils/useToast";

import {
  Box,
  alertVariants,
  Alert,
  Button,
  Input,
  AddIcon,
  RemoveIcon,
  Flex,
  Spinner,
} from "@pancakeswap/uikit";

const Blog = () => {
  const [isLoading, setLoading] = useState(false);

  const { toastSuccess, toastError } = useToast();

  useEffect(() => {
    queryNFTBalnce()
  }, []);
  const [ownerTotal,setOwnerTotal] = useState(0);
  const queryNFTBalnce=async ()=>{
    const WasaiNFT = await getContractHandler("WasaiNFT");

    console.log("WasaiNFT", WasaiNFT);

    if (!WasaiNFT) return;

    const account = await _getAddress();
    //随机数
    const totals = await WasaiNFT.balanceOf(account);

    setOwnerTotal(Number(utils.formatUnits(totals, 0) ))
  

        
        
  }


  const claimToken = async () => {
    try {
      //开始 Loading
      setLoading(true);

      const isOk = IsBscNet();

      if (!isOk) {
        await switchToMainnet();
      }

      await connectMetamask();

 
      const WasaiMint = await getContractHandler("WasaiNFT");
 
      if (!WasaiMint) {
        console.log("加载失败");
        return;
      }

      const account = await _getAddress();
 
      const hash = await WasaiMint.mint();

      watchTransaction(hash.hash, (ress, boll) => {
        console.log(ress);
        console.log(ress.status);
        console.log(boll);
        if (boll) {
          console.log(ress);
          console.log(boll);
          //todo 提示成功  loading不结束

          // Loading
          setLoading(false);

          toastSuccess("Successfully submit Claim!");
        }
      });
    } catch (e) {
      //报错结束  Loading
      setLoading(false);
      toastError(e?.message);
      console.error(e?.message);
    }
  };

  const { t } = useTranslation();
  return (
    <div className="blog padding-top padding-bottom">
      <div className="container">
        <div className="blog__wrapper">
          <div className="row ">
            <div className="col-lg-8">
              <article>
                <div className="post-item-2">
                  <div className="post-inner">
                    <div className="post-thumb mb-30 px-30 pt-30">
                      <img src={require('../../assets/images/blog/001.png')} alt="blog" />
                    </div>
                    <div className="post-content pt-0">
                      <h3>{t("NVF x Oasis Token  Airdrop Activity")}</h3>
                      <ul className="blog__meta d-flex flex-wrap align-items-center mb-4">
                     
                      </ul>
                      <p>
                      {t("In this rapidly evolving digital era, do you aspire to own a unique and precious digital asset? NFTs (Non-Fungible Tokens) are not just unique identifiers for digital content like art, music, and videos; they are symbols of future wealth. Now, by holding an NFT, you have the opportunity to earn NVF tokens and be a part of the digital economy's rise!")}
                      </p>
                      <blockquote className="single-quote mb-30">
                        <div className="quotes">
                        {t("NFTs are digital artworks powered by blockchain technology, each piece being utterly unique and impossible to replicate. They can be paintings from renowned artists, music albums from celebrated singers, or your favorite movie clips. By owning an NFT, you become the true owner of these digital artworks, showcasing your unique taste and collector's eye.")}


                          
                        </div>
                      </blockquote>
                      <p>
                      {t("To reward our NFT holders, we proudly introduce NVF tokens. Holders of NFTs will have the chance to earn NVF tokens, which can be redeemed for various rare goods and services on our platform. Additionally, they can participate in shaping the future of the digital economy ecosystem, sharing in its prosperity.")}


                      </p>
                     

                      <p>
                      {t("Join us and embark on your digital future journey! NFTs and NVF tokens will take you into a brand-new world of digital economy, filled with endless possibilities and opportunities. Don't hesitate, act now!")}


                      </p>
 
                      <p>
                       {t("afe and Secure: Based on advanced blockchain technology, ensuring every transaction is transparent, immutable, and secure.")}
                      </p>
                      <p>
                        {t("Diverse Collection: A vast selection of NFTs for you to choose from, satisfying your diverse needs.")}
                      </p>

                      <p>
                        {t("Professional Service: We provide comprehensive support, making it easy for you to navigate and enjoy the charm of digital art.")}
                      </p>

                      <div className="tags-area">
                        <ul className="tags lab-ul justify-content-center">
                          {/* <li>
                            <a href="#">NFTs</a>
                          </li> */}
                          <li>
                            <a href="#" className="active">
                              Landing
                            </a>
                          </li>
                       {/*    <li>
                            <a href="#">Portfolio</a>
                          </li> */}
                        </ul>
                        <ul className="social justify-content-center">
                          <li className="social__item">
                            <a href="#" className="social__link">
                              <i className="fab fa-twitter"></i>
                            </a>
                          </li>
                           
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
          

                 

                
              </article>
            </div>
            <div className="col-lg-4">
              <aside className="ps-lg-4">
          {/*       <div className="widget widget-search">
                  <div className="widget__header">
                    <h5>Search keywords</h5>
                  </div>
                  <div className="widget-search-inner">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                        aria-label="Search bar"
                      />
                      <button className="search-icon">
                        <i className="icofont-search"></i>
                      </button>
                    </div>
                  </div>
                </div> */}

                <div className="widget widget-post">
                  <div className="widget__header">
                    <h5>{t("Claim Airdrop")}</h5>
                  </div>
                  <ul className="lab-ul widget-wrapper">
                    <li className="widget-post-item">
                      <div className="post-thumb">
                         
                          <img
                            src={require('../../assets/images/blog/03.jpg')}
                            alt="product"
                          />
                     
                      </div>
                      <div className="post-content">
                        <a href="blog-single.html">
                          <h6>{t("NFT holdings")}</h6>
                        </a>
                        <p>{ownerTotal} NFTS</p>
                      </div>
                    </li>
                    <li className="widget-post-item">
                      <div className="post-thumb">
                       
                          <img
                            src={require('../../assets/images/blog/favicon.png')}
                            alt="product"
                          />
                      
                      </div>
                      <div className="post-content">
                        
                          <h6>Number of NVF tokens </h6>
                        
                        <p>{ownerTotal*10000} NVF TOKEN</p>
                      </div>
                    </li>
                     
                    <li className="widget-post-item">
                       
                      <div className="post-content">
                       
                                  <Button
                                       variant={isLoading? "subtle": "success"}
                                      style={{ marginLeft: "30%",width:"100%" }}
                                      onClick={() => {
                                        
                                         //  openBoxSign(item.id, item.number, item.sign, item.to_address)
                                         claimToken()

                                      }}
                                    >
                                      {t("Claim Airdrop")}
                                    </Button>
                      </div>
                    </li>
                  </ul>
                </div>

                 

                 
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
