/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:17:45
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-15 12:37:57
 * @FilePath: \wasai-nft-web\src\components\pagehtml\FAQSection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { useState } from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { useTranslation } from '../../utils/contexts/Localization'
function FAQSection() {
  const { t } = useTranslation()
  const [ show , setShow] = useState(false)
  const changshow =()=>{
    setShow(!show)
  }
  const [ shows , setShows] = useState(false)
  const changshows =()=>{
    setShows(!shows)
  }
  return (
    <section id="faq" className="faq padding-top padding-bottom">
      <div className="container">
        <div className="section-header text-center">
          <p className="subtitle">{t('Questions & Answers')}</p>
          <h2>{t('Frequently Asked Questions')}</h2>
        </div>
        <div className="faq__wrapper">
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="accordion" id="faqAccordion1">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="accordion__item" data-aos="fade-up" data-aos-duration="1000">
                      <div className="accordion__header" id="faq1">
                        <button className="accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody1" aria-expanded="false" aria-controls="faqBody1"  onClick={()=>changshow()}>
                          {t('What is an NFT avatar')} <span className="plus-icon"></span>
                        </button>
                      </div>
                      <div id="faqBody1" className={`accordion-collapse collapse ${show ? 'show' : ''}`} aria-labelledby="faq1" data-bs-parent="#faqAccordion1">
                        <div className="accordion__body">
                          
                          {t('Holders of NFTs will not only receive a highly collectible artwork, but also a symbol of cultural identity. We hope that through this series of NFTs, more people can feel the charm of traditional Chinese culture and the innovative power of modern art, while also injecting more cultural connotations and artistic value into the NFT field!')}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 同样的结构重复两次 */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="accordion" id="faqAccordion2">
                <div className="row g-4">
                  <div className="col-12">
                    <div className="accordion__item" data-aos="fade-up" data-aos-duration="1000">
                      <div className="accordion__header" id="faq1-two">
                        <button className="accordion__button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqBody1-two" aria-expanded="false" aria-controls="faqBody1-two" onClick={()=>changshows()}>
                          {t('NFT market response')} <span className="plus-icon"></span>
                        </button>
                      </div>
                      <div id="faqBody1-two" className={`accordion-collapse collapse ${shows ? 'show' : ''}`} aria-labelledby="faq1-two" data-bs-parent="#faqAccordion2">
                        <div className="accordion__body">
                          {t('In recent years, NFT avatars have received widespread attention and pursuit. Many celebrities and artists have begun to venture into the field of NFT avatars as a new form of artistic expression and investment channel. Some well-known NFT avatar projects have even achieved great success in the market, such as CryptoPunks, Bored Ape Yacht Club (BAYC), and so on.')}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 同样的结构重复两次 */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQSection;
