/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-08 10:18:25
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-14 20:34:08
 * @FilePath: \wasai-nft-web\src\components\pagehtml\AboutSection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { NavLink } from 'react-router-dom';
import { useTranslation } from '../../utils/contexts/Localization'
function AboutSection() {
  const { t } = useTranslation()
  return (
    <section className="about padding-top padding-bottom" id="about">
      <div className="container">
        <div className="about__wrapper">
          <div className="row g-5">
            <div className="col-lg-6">
              <div className="about__thumb" data-aos="fade-up" data-aos-duration="1500">
                {/* <img src="assets/images/about/01.png" alt="About Image" /> */}
                <img src={require('../../assets/images/about/01.jpg')} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about__content" data-aos="fade-up" data-aos-duration="2000">
                <p className="subtitle">{t("The Story")}</p>
                <h2>{t('About us')}</h2>
                <p>{t('Holders of NFTs will not only receive a highly collectible artwork, but also a symbol of cultural identity. We hope that through this series of NFTs, more people can feel the charm of traditional Chinese culture and the innovative power of modern art, while also injecting more cultural connotations and artistic value into the NFT field!')}</p>
                {/* <p>{t('Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature.')}</p> */}
                <div className="mint-step">
                  <p className="subtitle color--secondary-color">{t('Easy Steps')}</p>
                  <h3>{t('How to Mint')}</h3>
                  <ul className="mint-step__list">
                    <li className="mint-step__list-item"><span className="color--secondary-color">1.</span> {t('Connect Your Wallet')}</li>
                    <li className="mint-step__list-item"><span className="color--secondary-color">2.</span> {t('Select Your Quantity')}</li>
                    <li className="mint-step__list-item"><span className="color--theme-color">3.</span> {t('Confirm the Transition')}</li>
                    <li className="mint-step__list-item"><span className="color--theme-color">4.</span> {t('Receive Your NFTs')}</li>
                  </ul>
                  <div className="btn-group">
                    {/* <a href="#" className="default-btn default-btn--secondary">Mint Now</a> */}
                    <NavLink to="/project_v1">
                <a className="default-btn default-btn--secondary">{t('Mint Now')}</a>
                  </NavLink>
                     <NavLink to="/project_v1">
                    <a href="#" className="default-btn">{t('Whitelist Now')}</a>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
