/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:15:52
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-06 22:18:23
 * @FilePath: \wasai-nft-web\src\components\pagehtml\TeamSection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { useTranslation } from '../../utils/contexts/Localization'
function TeamSection() {
  const { t } = useTranslation()
  return (
    <section className="team padding-top padding-bottom" style={{backgroundImage: "url(" + require('../../assets/images/team/bg.jpg') + ")"}} id="team">
      <div className="container">
        <div className="section-header text-center">
          <p className="subtitle">Team Member</p>
          <h2>Meet the Crew</h2>
        </div>
        <div className="team__wrapper">
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="team__item">
                <div className="team__item-inner">
                  <div className="team__item-thumb">
                    <img src={require('../../assets/images/team/01.jpg')} alt="Team Image" />
                  </div>
                  <div className="team__item-content">
                    <div className="team__item-author">
                      <h4><a href="team-single.html">{t('Harry Abraham')}</a> </h4>
                      <p>{t('Founder')}</p>
                    </div>
                    <p>{t('Contrar info popular belief is not simp has roots info piece arei classica from looked upone info the more obsc latin cites of the word in')}</p>
                    <ul className="social">
                      <li><a href="#" className="social__link"><i className="fa-brands fa-twitter"></i></a></li>
                      <li><a href="#" className="social__link"><i className="fa-brands fa-facebook-f"></i></a></li>
                      <li><a href="#" className="social__link"><i className="fa-brands fa-linkedin-in"></i></a></li>
                      <li><a href="#" className="social__link"><i className="fa-brands fa-instagram"></i></a></li>
                    </ul>
                  </div>
                </div>
                <span className="svg-shape">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="210px" height="10px">
                    <path fillRule="evenodd" fillOpacity="0.102" d=" M5.000,-0.001 L30.000,-0.001 L25.000,9.999 L-0.000,9.999 L5.000,-0.001 Z" />
                    <path fillRule="evenodd" fillOpacity="0.302" d=" M35.000,-0.001 L60.000,-0.001 L55.000,9.999 L30.000,9.999 L35.000,-0.001 Z" />
                    <path fillRule="evenodd" fillOpacity="0.502" d=" M65.000,-0.001 L90.000,-0.001 L85.000,9.999 L60.000,9.999 L65.000,-0.001 Z" />
                    <path fillRule="evenodd" fillOpacity="0.702" d=" M95.000,-0.001 L120.000,-0.001 L115.000,9.999 L90.000,9.999 L95.000,-0.001 Z" />
                    <path fillRule="evenodd" fillOpacity="0.8" d=" M125.000,-0.001 L150.000,-0.001 L145.000,9.999 L120.000,9.999 L125.000,-0.001 Z" />
                    <path fillRule="evenodd" fillOpacity="0.902" d=" M155.000,-0.001 L180.000,-0.001 L175.000,9.999 L150.000,9.999 L155.000,-0.001 Z" />
                    <path fillRule="evenodd" d=" M185.000,-0.001 L210.000,-0.001 L210.000,9.999 L180.000,9.999 L185.000,-0.001 Z" />
                  </svg>
                </span>
              </div>
            </div>
            {/* 同样的结构重复两次 */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default TeamSection;
