/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:10:21
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-06 12:13:46
 * @FilePath: \wasai-nft-web\src\components\pagehtml\RoadmapSection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { useTranslation } from '../../utils/contexts/Localization'
function RoadmapSection() {
  const { t } = useTranslation()
  return (
    <section className="roadmap roadmap--style1 padding-top padding-bottom" id="roadmap" style={{backgroundImage: "url(" + require('../../assets/images/roadmap/bg.jpg') + ")"}}>
      <div className="container">
        <div className="section-header text-center">
          <p className="subtitle">{t('Roadmap')}</p>
          <h2>{t('How it all started')}</h2>
        </div>
        <div className="roadmap__wrapper">
          <div className="row gy-4 gy-md-0 gx-5">
            <div className="col-md-6 offset-md-6">
              <div className=" roadmap__item ms-md-4 aos-init" data-aos="fade-left" data-aos-duration="800">
                <div className="roadmap__item-inner">
                  <div className="roadmap__item-content">
                    <div className="roadmap__item-header">
                      <h4>{t('Launched Initialized')}</h4>
                      <p>10%</p>
                    </div>
                    <p>{t('Contrar to popular belief Lorem Ipsum is not simpy random text info has roots info a piece of classical Latin literature from 4BCS making it over 2000 years old looked upone of the more obscure latin word consectetur cites of the word in')}</p>
                  </div>
                </div>
                <span className="svg-shape"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="210px" height="10px">
                  <path fillRule="evenodd" fillOpacity="0.102" d=" M5.000,-0.001 L30.000,-0.001 L25.000,9.999 L-0.000,9.999 L5.000,-0.001 Z" />
                  <path fillRule="evenodd" fillOpacity="0.302" d=" M35.000,-0.001 L60.000,-0.001 L55.000,9.999 L30.000,9.999 L35.000,-0.001 Z" />
                  <path fillRule="evenodd" fillOpacity="0.502" d=" M65.000,-0.001 L90.000,-0.001 L85.000,9.999 L60.000,9.999 L65.000,-0.001 Z" />
                  <path fillRule="evenodd" fillOpacity="0.702" d=" M95.000,-0.001 L120.000,-0.001 L115.000,9.999 L90.000,9.999 L95.000,-0.001 Z" />
                  <path fillRule="evenodd" fillOpacity="0.8" d=" M125.000,-0.001 L150.000,-0.001 L145.000,9.999 L120.000,9.999 L125.000,-0.001 Z" />
                  <path fillRule="evenodd" fillOpacity="0.902" d=" M155.000,-0.001 L180.000,-0.001 L175.000,9.999 L150.000,9.999 L155.000,-0.001 Z" />
                  <path fillRule="evenodd" d=" M185.000,-0.001 L210.000,-0.001 L210.000,9.999 L180.000,9.999 L185.000,-0.001 Z" />
                </svg></span>
              </div>
            </div>
            {/* 这里应该包含其他 roadmap__item 元素的代码 */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default RoadmapSection;
