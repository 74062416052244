/*
 * @Author: ahsdxy 2729945316@qq.com
 * @Date: 2024-05-06 12:20:17
 * @LastEditors: ahsdxy 2729945316@qq.com
 * @LastEditTime: 2024-05-06 12:20:28
 * @FilePath: \wasai-nft-web\src\components\pagehtml\PartnerSection.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import '../../assets/images/favicon.png';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/aos.css';
import '../../assets/css/all.min.css';
import '../../assets/css/lightcase.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/style.css';
import { useTranslation } from '../../utils/contexts/Localization'
function PartnerSection() {
  const { t } = useTranslation()
  return (
    <section className="partner padding-top padding-bottom">
      <div className="container">
        <div className="section-header text-center">
          <p className="subtitle">{t('Partners')}</p>
          <h2>{t('Our Best Partners')}</h2>
        </div>
        <div className="partner__wrapper">
          <div className="row g-0 row-cols-2 row-cols-md-3 row-cols-xl-5 ">
            <div className="col">
              <div className="partner__item">
                <img src={require('../../assets/images/partner/01.png')} alt="Partner Image" />
              </div>
            </div>
            {/* Repeat the same structure for the other partner items */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnerSection;
