import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import Button from '../../button';
import { ImgUrl } from '../../../utils/Config';
import axios from "axios";
import { BigNumber, Contract, providers, utils } from 'ethers'
import useToast from '../../../utils/useToast'
import { getContractHandler, _getAddress,watchTransaction } from '../../../utils/ethereum'
import { Constants } from '../../../utils/Config'
import { useTranslation } from '../../../utils/contexts/Localization'
import video from '../../../assets/detail.mp4'
import video2 from '../../../assets/mintvideo.mp4'
import {
    Input, AddIcon, RemoveIcon
} from '@pancakeswap/uikit'
import styled from 'styled-components'

const StyleInput = styled(Input)`
  width: 260px;
  background-color: rgba(0, 0, 0, 0);
  border:1px solid rgba(0, 0, 0, 0)

  border-bottom: 1px solid #ffffff85;
  text-align: left;
  color: #000  ;
  font-color: #000;
  margin-top: -4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  height:30px;
`


function Banner(props) {
    const { item, isBox } = props;
    console.log("item:", item, isBox)
    const ids = utils.formatUnits(item._hex, 0);
    const [attr, setAttr] = useState("")
    const [price, setPrice] = useState(0)

    const [nft, setNFT] = useState(null)
    const  [isNFT, setIsNft] =  useState(isBox) ;

    const { toastSuccess, toastError } = useToast()
    const { t } = useTranslation();
    const [isVideo,setisVideo] = useState(false) 


    const [loadding,setLoading] = useState(false) 


    const changePrice = (e) => {
        setPrice(e.target.value);
    }



    useEffect(() => {
        if (isNFT) {
            //  queryTokens(); 
            /*  queryImg(); */
        }

    }, []);

    const openBox = async () => {
        try {
            setLoading(true)
            const account = await _getAddress()

            console.log("ids",ids)

            const WasaiNFT = await getContractHandler('WasaiNFT')
            if (!WasaiNFT) return;
            // const att = await WasaiMint.openBox(ids)
            //检查授权
            
                const response =   await axios
                .get(ImgUrl+"/sign/" + ids)
                const sign = response.data.data;
 

            const hash =   await WasaiNFT.openBox(ids,sign)
            
            setisVideo(true)
            //提交任务后开始播放打开盒子视频
            watchTransaction(hash.hash, (ress, boll) => {
                console.log(ress);
                console.log(ress.status);
                console.log(boll);
                if (boll) {
                console.log(ress);
                console.log(boll);
                //todo 提示成功  loading不结束
                setIsNft(true)
                // Loading
                // setLoading(false);
                setLoading(false)
                //todo  任务执行完成后刷新一下 queryTokens（）
                // queryTokens()
                toastSuccess('Successfully submit Open Box!')
                }
            });
   

        } catch (e) {
            setLoading(false)
            toastError(e?.message)
            console.error(e?.message)
        }

    }

    const sellBox = async () => {


        try {

            const account = await _getAddress()

            const MarketPlace = await getContractHandler('MarketPlace')
            const WasaiNFT = await getContractHandler('WasaiNFT')
            if (!MarketPlace) return;
            // const att = await WasaiMint.openBox(ids)
            //检查授权
            //如果没有授权，先提示授权

            if (price <= 0) {
                toastError("请输入正确金额")
                return
            }

            const is = await WasaiNFT.isApprovedForAll(account, Constants.Contract.MarketPlace)
            if (!is) {
                toastError(t("NO Approved"))
                await WasaiNFT.setApprovalForAll(Constants.Contract.MarketPlace, true);
                toastSuccess(t('Approved'))
                return;
            }
            await MarketPlace.createOrder(Constants.Contract.WasaiNFT, ids, price * 1000000)
            toastSuccess('Successfully submit Open Box!')

        } catch (e) {

            toastError(e?.message)
            console.error(e?.message)
        }


    }

    return (
        <div className="project-box">


            <div className="content" style={{ marginTop: "0px" }}>

                <div className="content_phone" style={{ width: "100%"  }} >


                    {isNFT && <img style={{ width: "100%" }} className="mask" src={ImgUrl+`/nft/images/${ids}.png`} alt="Risebot" />
                    }

 

                    {/*  */}

                    {!isNFT&&!isVideo && <video x5-video-player-type="h5" x5-video-player-fullscreen="true" playsinline="true" webkit-playsinline="true" x-webkit-airplay="true" className="mw-100 db center" autoPlay loop muted >
                        <source src={video2} />
                    </video>}

                    {!isNFT&&isVideo && <video x5-video-player-type="h5" x5-video-player-fullscreen="true" playsinline="true" webkit-playsinline="true" x-webkit-airplay="true" className="mw-100 db center" autoPlay loop muted >
                        <source src={video} />
                    </video>}
                </div>

                <div className="content-inner">
                    {/*  <h5 className="heading"><Link to="/project_list">{ids}</Link></h5> */}

                    <ul>
                        <li>
                            <p className="text">NFT Id:</p>
                            <p className="price">{ids}</p>
                        </li>
                     {/*    <li>
                            <p className="text">:</p>
                            <p className="price"><StyleInput value={price} onChange={changePrice} style={{ width: "60px" }} placeholder="Your price" /></p>
                        </li> */}
                    </ul>

                </div>

            </div>
            {/*  {isNFT && <Button title={t('Sell')} onClick={sellBox} addclass='style2' />} */}
            {!isNFT && <Button title={loadding?t( "OPEN ..." ):t('OPEN BOX')}  disabled={loadding}   onClick={openBox} addclass='style2' />}

        </div>
    );
}

export default Banner;